<!--
 * @Author: 月魂
 * @Date: 2021-06-04 09:16:31
 * @LastEditTime: 2022-11-07 11:59:08
 * @LastEditors: 月魂
 * @Description:
 * @FilePath: \nep-concert\src\views\Screen.vue
-->
<template>
  <div class="box">
    <!-- <img :src="this.$t('mobilePosterUrl')" alt="" class="mobile">
    <img :src="this.$t('pcPosterUrl')" alt="" class="pc"> -->
    <!-- <img :src="this.$t('mobilePosterUrl')" alt="" class="mobile"> -->
    <img src="../assets/mobile.jpg" alt="" class="mobile">
    <img src="../assets/pc.jpg" alt="" class="pc">
    <img src="../assets/enter.png" alt="" class="img btn" @click="onClick">
  </div>
</template>

<script>
import Vue from 'vue'
import { Button } from 'vant'
Vue.use(Button)

export default {
  name: 'Screen',
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$router.push('/index')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  overflow: hidden;
  position: relative;
  .img {
    display: block;
    width: 100px;
  }
  .mobile {
    width: 100%;
    height: 100%;
    display: block;
  }
  .pc {
    width: 100%;
    height: 100%;
    display: none;
  }
  @media (min-width: 800px) {
    .mobile {
      display: none;
    }
    .pc {
      display: block;
    }
    .img {
      width: 200px;
    }
  }
  .btn {
    position: absolute;
    bottom: 14%;
    right: 6px;
    border-radius: 8px;
    width: 90px;
    @media (min-width: 800px) {
      & {
        bottom: 8%;
        right: 2%;
      }
    }
  }
}
</style>
